import _tslib from "tslib";
import _react from "react";
import _singlentone from "./singlentone";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var tslib_1 = _tslib;
var React = _react;
var singlentone_1 = _singlentone;

exports.styleSinglentone = function () {
  var sheet = singlentone_1.stylesheetSinglentone();
  return function (_super) {
    tslib_1.__extends(Sheet, _super);

    function Sheet() {
      return _super !== null && _super.apply(this, arguments) || this;
    }

    Sheet.prototype.componentDidMount = function () {
      sheet.add(this.props.styles);
    };

    Sheet.prototype.componentWillUnmount = function () {
      sheet.remove();
    };

    Sheet.prototype.render = function () {
      return null;
    };

    return Sheet;
  }(React.PureComponent);
};

export default exports;