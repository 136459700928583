import _component from "./component";
import _singlentone from "./singlentone";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var component_1 = _component;
exports.styleSinglentone = component_1.styleSinglentone;
var singlentone_1 = _singlentone;
exports.stylesheetSinglentone = singlentone_1.stylesheetSinglentone;
export default exports;
export const __esModule = exports.__esModule,
      styleSinglentone = exports.styleSinglentone,
      stylesheetSinglentone = exports.stylesheetSinglentone;